@import "@/scss/_variables.scss";















































.pre-dimension-main {
  >.grid {
    grid-column-gap: 160px;
  }

  .page-title {
    margin: 15px 0 32px 0;

    font-size: 1.75rem;
    line-height: 2.2rem;
    color: #E3000B;
  }

  .hide-result {
    grid-template-columns: 1fr;
  }

  .input-manager {
    margin-bottom: 64px;
  }

  .grid {
    &.mobile {
      .result {
        position: initial;

        width: calc(100% + 16px * 2);
        padding: 32px 16px 0 16px;
        margin-left: -16px;
        overflow: hidden;
      }
    }
  }

  .grid-item {
    position: relative;
  }

  .result {
    padding: 32px 32px 0 32px;
    margin-bottom: 64px;

    background-color: #FFFFFF;
    border: 1px solid #CAD3D9;

    position: fixed;
    top: 100px;

    overflow-y: auto;
    max-height: calc(100% - 120px);

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}
