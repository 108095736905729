@import "@/scss/_variables.scss";





































































.pre-dimension-main {
  .result-container {
    tbody {
      .result-primary-item {
        &:first-child {

        }
      }

      tr {
        &.selected {
          .selection-input {
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }

      td {
        min-width: 100px;

        @media (max-width: $width-tablet) {
          min-width: initial;
        }

        button {
          width: auto;
          height: 100%;
          padding: 4px;

          font-family: futura-pt, sans-serif;
          font-size: 0.762rem;
          background: transparent;
          border: none;
          text-align: left;
          font-weight: bold;
          color: #59915D;
          cursor: pointer;

          &.exceeded {
            color: rgba(227, 0, 11, 1);
          }

          &.selected {
            background-color: rgba(89, 145, 93, 0.2);

            &.exceeded {
              background-color: rgba(227, 0, 11, 0.2);
            }
          }
        }

        input[type="radio"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          display: inline-block;
          width: 12px;
          height: 12px;
          padding: 2px;
          margin: 0;

          background-clip: content-box;
          border: 1px solid #bbbbbb;
          background-color: transparent;
          border-radius: 50%;
        }

        input[type="radio"]:checked {
          border: 1px solid #E3000B;
          background-color: #E3000B;
        }

        label {
          margin-left: 8px;

          font-family: futura-pt-book, sans-serif;
          font-size: 0.762rem;
        }
      }
    }
  }
}
