@import "@/scss/_variables.scss";






























































































































































.pre-dimension-main {
  .result-container {
    > .title-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .title {
        font-size: 1rem;
        line-height: 1rem;
        color: #E3000B;
        margin-bottom: 0;
      }

      .title-help {
        background: transparent;
        border: none;
        cursor: pointer;
      }
    }

    .spacing {
      margin-bottom: 48px;
    }

    .result-wrapper {
      display: grid;
      width: 100%;

      font-size: 0.762rem;

      &.main {
        grid-template-columns: 3fr 1fr 1fr;
      }

      &.secondary {
        grid-template-columns: 3fr 1fr 1fr 1fr;
      }

      @media (max-width: $width-tablet) {
        &.main {
          grid-template-columns: 2fr 1fr 1fr;
        }

        &.secondary {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        font-size: 0.75rem;
      }

      thead, tbody, tr {
        display: contents;
      }

      tr {
        height: auto;

        &.border-bottom {
          td {
            border-bottom: 1px solid #69777F;
          }
        }

        td {
          width: 100%;
          height: 100%;
          padding: 4px 0 4px 0;

          &.underline {
            text-decoration: underline;
          }

          &.green {
            color: #59915D;
          }

          &.center {
            display: flex;
            align-items: center;
          }
        }
      }

      thead {
        font-family: futura-pt-condensed, sans-serif;
        color: #293136;
        font-weight: bold;

        td {
          display: flex;
          align-items: flex-end;
          padding: 8px 0 16px 0;

          border-bottom: 1px solid #69777F;

          img {
            width: 60px;
            height: 24px;
          }

          &.space-between {
            flex-direction: column;
            align-items: initial;
            gap: 8px;
          }
        }
      }

      tbody {
        font-family: futura-pt-book, sans-serif;
      }
    }

    .output-result-list {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;

      &:before {
        content: '';
        position: absolute;
        left: -32px;
        width: calc(100% + 64px);
        height: 1px;

        background-color: #CAD3D9;
      }

      .output-result-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-family: futura-pt-condensed, sans-serif;

        &:first-child {
          padding: 16px 32px 16px 0px;
        }

        &:last-child {
          padding: 16px 0px 16px 32px;

          border-right: none;
        }

        font-size: 0.762rem;
        color: #293136;
        font-weight: bold;
        border: none;
        background: none;
        cursor: pointer;

        border-right: 1px solid #CAD3D9;

        @media (max-width: $width-tablet) {
          font-size: 1rem;
        }

        img {
          width: 24px;
          height: 24px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
