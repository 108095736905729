@import "@/scss/_variables.scss";




















.pre-dimension-main .result-wrapper .result-secondary-item {
  &:first-child {
    td {
      padding-top: 16px !important;
    }
  }

  &:nth-child(2) {
    td {
      padding-bottom: 16px !important;
    }
  }
}
