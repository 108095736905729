@import "@/scss/_variables.scss";































.pre-dimension-main {
  .result-utilization-item {
    &:last-child {
      td {
        padding-top: 16px !important;
      }
    }
  }

  .result-wrapper tbody tr td {
    &.ok {
      color: #59915D;
    }

    &.exceed {
      color: #E3000B;
    }
  }
}
